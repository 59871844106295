<template>
    <!--Need to include these html file in app-->
    <!-- <o365-cdn-src name="froala-editor/css/froala_editor.pkgd.min.css"></o365-cdn-src> -->
    <!-- <o365-cdn-src name="froala-editor" is-module="false"></o365-cdn-src> -->

    <div class="text-dark fix-color fr-view h-100 w-100" :id="props.boxId"></div>
    <OModal :title="$t('Images')" ref="modalImages" class="modal modal-xl">
        <div class="modal-body">
            <OTabs @onShown="handleImageTabChange">
                <OTab :title="$t('Uploaded images')" active>
                    <div class="mt-3 row">
                        <div class="col-6">
                            <div clasSs="mb-1">
                                <label class="form-label" for="image-name"> {{ $t("Name") }} </label>
                                <input class="form-control" id="image-name" type="text" v-model="props.imageDataObject.current.Name" :disabled="typeof props.imageDataObject.current.index === 'undefined'">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1">
                                <label class="form-label" for="image-alttext"> {{ $t("Alt Text") }} </label>
                                <input class="form-control" id="image-alttext" type="text" v-model="props.imageDataObject.current.AltText" :disabled="typeof props.imageDataObject.current.index === 'undefined'">
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="mb-1">
                                <label class="form-label" for="image-url"> {{ $t("Image URL") }} </label>
                                <input class="form-control" id="image-url" type="text" :value="props.imageDataObject.current.index ? '/api/file/view/' + props.imageDataObject.viewName + '/' + props.imageDataObject.current.PrimKey + '/' + props.imageDataObject.current.FileName : ''" :disabled="typeof props.imageDataObject.current.index === 'undefined'">
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div class="d-inline-flex flex-wrap align-items-center overflow-auto w-100" style="max-height: 650px;" @scroll="infiniteScrollImages($event, props.imageDataObject)">
                        <div v-for="image in props.imageDataObject.data" class="p-1 m-1 position-relative select-image" @click="props.imageDataObject.setCurrentIndex(image.index)">
                            <div class="position-absolute top-50 start-50 translate-middle bg-white py-0 px-1 rounded">
                                <i v-if="image.index === props.imageDataObject.current.index" style="font-size: 150%;" class="bi bi-check2-circle"></i>
                            </div>
                            <img class="img-thumbnail" :class="{ 'selected-image': image.index === props.imageDataObject.current.index }" :src="'/api/file/view/' + props.imageDataObject.viewName + '/' + image.PrimKey + '/' + image.FileName + '?scale=thumbnail'">
                        </div>
                    </div>
                </OTab>
                <OTab :title="$t('Insert image from URL')">
                    <div class="mt-3">
                        <div class="mb-3">
                            <label class="form-label" for="image-filename"> {{ $t("URL") }} </label>
                            <input class="form-control" id="image-filename" type="text" v-model="insertImageUrl">
                        </div>
                    </div>
                </OTab>
            </OTabs>
        </div>
        <div class="modal-footer">
            <!-- Uploaded Images -->
            <div v-if="imageModalTabNo === 0">
                <button @click="insertImage(props.imageDataObject, null)" class="btn btn-sm btn-primary">{{ $t("Insert image") }}</button>
                <FileUpload :dataObject="props.imageDataObject" multiple="multiple" class="btn btn-sm btn-outline-primary mx-2">{{ $t("Upload image") }}</FileUpload>
                <button class="btn btn-sm btn-outline-primary" :class="{ disabled: !props.imageDataObject.current.hasChanges }" @click="props.imageDataObject.save()">{{ $t("Save") }}</button>
            </div>
            <!-- Insert Image From URL -->
            <div v-if="imageModalTabNo === 1">
                <button @click="insertImage(null, insertImageUrl)" class="btn btn-sm btn-primary">{{ $t('Insert image') }}</button>
            </div>
        </div>
    </OModal>
</template>
 
<script setup lang="ts">
    //Uploading images works poorly. Shows Error even though it works.
    import DataObject from "o365.vue.ts"
    import { onMounted, defineProps, ref, Ref } from "vue";  
    import { setActiveEditor, getActiveEditor, EditorInstance, selectedNode, selectedNodeOffset, setSelectedNodeAndOffset } from "o365.Froala.Froala.ts";
    import FileUpload from "o365.vue.components.FileUploadButton.vue";
    import $t from "o365.modules.translate.ts";

    const props = defineProps<{
        dataObject: DataObject,
        imageDataObject: DataObject,
        contentColumn: string,
        boxId: string
    }>();

    const modalImages:Ref = ref(null);
    const insertImageUrl:Ref<string> = ref("");
    const imageModalTabNo:Ref<number> = ref(0);

    props.imageDataObject.dynamicLoading.enabled = true;

    props.imageDataObject.on("CurrentIndexChanged", () => {
        props.imageDataObject.recordSource.refreshRow();
    });

    props.imageDataObject.fileUpload.onCompleted = () => {
        setTimeout(() => {
            if (document.querySelector(".change-summary-image img")) {
                const summaryImg = document.querySelector(".change-summary-image img")?.src;
                document.querySelector(".change-summary-image img")!.src = summaryImg;
            }
            if (document.querySelector(".selected-image")) {
                const selectedImageSrc = document.querySelector(".selected-image")?.src;
                document.querySelector(".selected-image")!.src = selectedImageSrc;
            }
        });
    }

    const handleImageTabChange = (tabs) => {
        const target = tabs.activeTab.dataset["bsTarget"];
        imageModalTabNo.value = parseInt(target.charAt(target.length - 1));
    };

    const infiniteScrollImages = (e:any, dataObject:DataObject) => {
        const clientHeight = e.target?.clientHeight + 1;
        const scrollHeight = e.target?.scrollHeight;
        const scrollTop = e.target?.scrollTop;

        if (scrollTop + clientHeight >= scrollHeight && dataObject) {
            dataObject.dynamicLoading.loadNextPage();
        }
    }

    const completeUpload = async (data: any) => {
        let url = `/api/file/view/${props.imageDataObject.viewName}/${props.imageDataObject.current.PrimKey}`

        insertImage(null, url)
    }

    const insertImage = (pDO:DataObject, pURL:string | null) => {
        let domain = window.location.protocol + "//" + window.location.host

        if (getActiveEditor(props.boxId)?.editor) {
            const img:HTMLImageElement = document.createElement("img");
            img.style.width = "300px";
            img.style.display = "block";
            img.style.verticalAlign = "top";
            img.style.margin = "5px auto";
            img.style.textAlign = "center";
            if (pDO) {
                img.src = `/api/file/view/${pDO.viewName}/${pDO.current.PrimKey}/${pDO.current.FileName}`;
            } else {
                img.src = pURL;
            }
            img.alt = pDO?.current?.AltText ? pDO.current.AltText : "";

            if (selectedNode && selectedNodeOffset) {
                window.getSelection().setBaseAndExtent(selectedNode, selectedNodeOffset, selectedNode, selectedNodeOffset);
            }

            getActiveEditor(props.boxId)?.editor.html.insert(img.outerHTML, true);
            getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId)?.contentColumn] = getActiveEditor(props.boxId)?.editor.html.get();
            modalImages.value.hide();
        }
    }

    FroalaEditor.DefineIconTemplate("bootstrap", '<i class="[ICON]"></i>');
    FroalaEditor.DefineIcon("saveButton", {ICON: "bi bi-save", template: "bootstrap"});

    FroalaEditor.DefineIcon("imageButton", {NAME: "insertImage", SVG_KEY: "insertImage"});
    FroalaEditor.RegisterCommand("customImages", {
        title: "Images",
        focus: false,
        undo: true,
        icon: "insertImage",
        refreshAfterCallback: true,
        callback: () => {
            props.imageDataObject.load();
            modalImages.value.show();
        }
    });

    FroalaEditor.RegisterQuickInsertButton('quickInsertImage', {
        icon: "insertImage",
        title: "Images",
        callback: function () {
            props.imageDataObject.load();
            modalImages.value.show();
        },
        undo: true
    });

    FroalaEditor.RegisterCommand("customSave", {
        title: "Save",
        focus: false,
        undo: false,
        icon: "saveButton",
        refreshAfterCallback: false,
        callback: () => {
            if (getActiveEditor(props.boxId)?.db.current.hasChanges) {
                getActiveEditor(props.boxId)?.db.save();
            }
        }
    });

    FroalaEditor.DefineIcon("toggleHeader", {ICON: "bi bi-arrows-expand", template: "bootstrap"});
    FroalaEditor.RegisterCommand("customToggleHeader", {
        title: "Toggle Header",
        focus: false,
        undo: false,
        icon: "toggleHeader",
        refreshAfterCallback: false,
        callback: () => {
            new bootstrap.Collapse(document.querySelector("#header-stuff")).toggle();
        }
    });

    FroalaEditor.DefineIconTemplate("lead_icon_template", "<span>Lead</span>");
    FroalaEditor.DefineIcon("leadIcon", { NAME: "Lead", template: "lead_icon_template" })
    FroalaEditor.RegisterCommand("customLeadStyle", {
        title: "Lead style",
        focus: false,
        undo: true,
        icon: "leadIcon",
        refreshAfterCallback: true,
        callback: () => {
            getActiveEditor(props.boxId)?.editor.paragraphStyle.apply("lead");
        }
    });
    
    onMounted(() => {

        let froalaOptions = {
            key: "1CC3kD9B5E4F6G4F3bHIMFF1EWBXIJb1BZLZFh1i1MXQLjE4C3F3I3B4D6C6E3C3F2==",
            height: "calc(100vh - 550px)",
            attribution: false,
            useClasses: true,
            pastePlain: true,
            wordPasteKeepFormatting: false,
            placeholderText: $t("Please elaborate the lesson learned by incorporating additional text, images, formatted URLs and other media to enhance the content"),
            imagePaste: false, // Because the event returns img as base64, custom event listener created instead
            imageStyles: {
                "rounded" : "Rounded",
                "border" : "Border",
            },
            charCounterCount: false,
            theme: "dark",
            toolbarInline: false,
            videoUpload: false,
            imageMove: true,
            saveInterval: 2500,
            fontSizeSelection: true,
            quickInsertButtons: ["quickInsertImage", "video", "embedly", "table", "ul", "ol", "hr"],
            imageEditButtons: ["imageAlign", "imageCaption", "imageRemove", "|", "imageLink", "linkOpen", "linkEdit", "linkRemove", "-", "imageDisplay", "imageStyle", "imageAlt", "imageSize"],
            fontSize: ["18","20", "22", "24", "26", "28", "30", "32", "34", "36", "40", "48", "60", "72", "96"],
            htmlRemoveTags: ["script"],
            pasteDeniedAttrs: ["id"],
            shortcutsEnabled: ["show", "bold", "italic", "underline", "indent", "outdent", "undo", "redo", "createLink"], // removed strikeThrough shortcut, because it uses Ctrl + S
            paragraphFormat: {
                N: "Normal",
                H1: "Heading 1",
                H2: "Heading 2",
                H3: "Heading 3",
                H4: "Heading 4",
                H5: "Heading 5",
                PRE: "Code"
            },
            paragraphFormatSelection: true,
            paragraphStyles: {
                "fr-text-gray":"Gray",
                "fr-text-bordered":"Bordered",
                "fr-text-spaced":"Spaced",
                "fr-text-uppercase":"Uppercase"
            },
            toolbarButtons: {
                moreText: {
                    buttons: ["bold", "italic", "underline", "strikeThrough", "subscript", "superscript", "fontFamily", "fontSize", "textColor", "backgroundColor", "inlineClass", "inlineStyle", "clearFormatting"]
                },
                moreParagraph: {
                    buttons: ["paragraphFormat", "customLeadStyle", "alignLeft", "alignCenter", "formatOLSimple", "alignRight", "alignJustify", "formatOL", "formatUL", "paragraphStyle", "lineHeight", "outdent", "indent", "quote"],
                    buttonsVisible: 2
                },
                moreRich: {
                    buttons: ["trackChanges", "insertLink", "customImages", "insertTable", "emoticons", "fontAwesome", "specialCharacters", "embedly", "insertHR"],
                    buttonsVisible: 4
                },
                moreMisc: {
                    buttons: ["undo", "redo", "customSave", "fullscreen", "print", "spellChecker", "selectAll", "html", "help"],
                    align: "right",
                    buttonsVisible: 3
                },
                trackChanges: {
                    buttons: ["showChanges", "applyAll", "removeAll", "applyLast", "removeLast"],
                    buttonsVisible: 0
                }
            },
            events: {
                "contentChanged": () => {
                    getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn] = getActiveEditor(props.boxId).editor.html.get();
                },
                "save.before": () => {
                    getActiveEditor(props.boxId).db.save();
                },
                "commands.before": (cmd) => {
                    setSelectedNodeAndOffset(window.getSelection().focusNode, window.getSelection().focusOffset);
                },
            },
        }

        const editorInstance: EditorInstance = {
            editor: new FroalaEditor(`#${props.boxId}`, froalaOptions, () => {
                getActiveEditor(props.boxId).editor.html.set(getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn]);

                getActiveEditor(props.boxId).db.on("onFieldChanged", () => {
                    if (getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn]) {
                        getActiveEditor(props.boxId).editor.html.set(getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn]);
                    }
                });

                getActiveEditor(props.boxId).db.on("ChangesCancelled", () => {
                    if (getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn]) {
                        getActiveEditor(props.boxId).editor.html.set(getActiveEditor(props.boxId).db.current[getActiveEditor(props.boxId).contentColumn]);
                    }
                });
            }),
            db: props.dataObject,
            contentColumn: props.contentColumn,
        };

        setActiveEditor(props.boxId, editorInstance);

        if (typeof getActiveEditor(props.boxId)?.editor === "object" && !Array.isArray(getActiveEditor(props.boxId)?.editor)) {
            getActiveEditor(props.boxId)?.editor.el.addEventListener("keydown", (e: KeyboardEvent) => {
            if (e.ctrlKey && e.key === "s") {
                e.preventDefault();
                getActiveEditor(props.boxId)?.db.save();
            }
            });
        }
    });
</script>

<style>
    /* When using dark mode, it will put h1-h6 in white text, because of Bootstrap reboot v5.3 */
    .fr-dropdown-list h1, .fr-dropdown-list h2, .fr-dropdown-list h3, .fr-dropdown-list h4, .fr-dropdown-list h5, .fr-dropdown-list h6 {
        color: black;
    }
    .fr-element.fr-view h1, .fr-element.fr-view h2, .fr-element.fr-view h3, .fr-element.fr-view h4, .fr-element.fr-view h5, .fr-element.fr-view h6 {
        color: black;
    }
    .fr-wrapper {
        min-height: 100%;
        min-width: 100%;
        overflow-y: auto;
    }
</style>